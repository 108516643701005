import LazyLoad from "vanilla-lazyload";


require('slick-carousel');
require('./rwd-checker');
require('./form-animation')


$(function () {
    const lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
        // aby użyć do elementów dodajemy klasę .lazy
        // zamiast src w img podajemy data-src
        // zamiast background-image podajemy data-bg
    });

    require('./animations');
    require('./dropdown-menu');
    require('./mobile-menu');
    require('./cookie-bar');
    require('./sticky-header');
    require('./apartment-slider');
    require('./top-slider');
    require('./slider-sections');
    require('./events-homepage');
    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });
    if($('.simplelightbox').length > 0){
        $('.simplelightbox').simpleLightbox();
    }
});


    $('.apartment__slider').slick({
        centerMode: true,
        centerPadding: '14%',
        slidesToShow: 1,
        variableWidth: true,
        adaptiveHeight: true,
        nextArrow: '<div class="arrow-slide arrow-slide--next"></div>',
        prevArrow: '<div class="arrow-slide arrow-slide--prev"></div>',
        appendArrows: $('.apartment__arrows')
        // responsive: [
        //     {
        //         breakpoint: 768,
        //         settings: {
        //             arrows: false,
        //             centerMode: true,
        //             centerPadding: '40px',
        //             slidesToShow: 3
        //         }
        //     },
        //     {
        //         breakpoint: 480,
        //         settings: {
        //             arrows: false,
        //             centerMode: true,
        //             centerPadding: '40px',
        //             slidesToShow: 1
        //         }
        //     }
        // ]
    }); 

